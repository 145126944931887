<template>
  <Layout>
    <PageHeader :title="title" class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-10 col-12">
          <div class="card">
            <div class="card-body d-flex flex-wrap justify-content-around align-items-start py-5 gap-3">
              <div class="card-body-inner">

              <vue-csv-import
              :inputClass="'csv-import-file'"
                :autoMatchFields="true"
                :canIgnore="true"
                :headers="false"
                :autoMatchIgnoreCase="true"
                :fileMimeTypes="csvFileType"
                v-model="parseCsv"
                :map-fields="[
                  'business_name',
                  'phone_number',
                  'business_email',
                  'website_url',
                  'street_address',
                  'city',
                  'zip_code',
                  'state',
                  'capterra_business_id',
                  'trustpilot_profile',
                  'google_business_id',
                  'image_url_link',
                  'logo',
                  'business_description',
                ]"
              >
                <template slot="error"> File type is invalid </template>
                <template slot="next" slot-scope="{ load }">
                  <button
                    @click.prevent="load"
                    class="
                      btn
                      border-0
                      px-5
                      fw-bold
                      text-white
                      btn btn-primary
                      waves-effect waves-light
                      
                      primary-button
                    "
                    v-b-modal.modal-5
                  >
                    Import File Data
                  </button>
                </template>

                <template slot="submit" slot-scope="{ submit }">
                  <button
                    @click.prevent="submit"
                    class="
                      btn
                      border-0
                      px-5
                      fw-bold
                      text-white
                      btn btn-primary
                      waves-effect waves-light
                      w-md
                      primary-button
                    "
                  >
                    Submit!
                  </button>
                  <vue-csv-map v-if="this.parseCsv != null"></vue-csv-map>
                </template>
              </vue-csv-import>
              <div class="col-12 col-lg-4 text-center csv_sample_download"><a class="btn btn-outline-dark py-2" :href="'/files/Example.csv'" download
                >Download Sample CSV File</a
              ></div>
              <div class="text-center">
                <button
                  v-if="this.parseCsv != null"
                  @click="checkData"
                  class="
                    btn
                    border-0
                    mb-3
                    px-5
                    fw-bold
                    text-white
                    btn btn-primary
                    waves-effect waves-light
                    w-md
                    primary-button
                  "
                >
                  Submit
                </button>
              </div>
              <div class="container">
                <div class="table-responsive">
                  <table v-if="this.parseCsv != null" class="table">
                    <thead>
                      <tr>
                        <th scope="col">Business Name</th>
                        <th scope="col">Business Mobile Phone</th>
                        <th scope="col">Business Email</th>
                        <th scope="col">Website URL</th>
                        <th scope="col">street Address</th>
                        <th scope="col">City</th>
                        <th scope="col">Zip Code</th>
                        <th scope="col">State</th>
                        <th scope="col">Capterra Business ID</th>
                        <th scope="col">Trustpilot Profile</th>
                        <th scope="col">Google Business Profile</th>
                        <th scope="col">Image Url</th>
                        <th scope="col">Logo Url</th>
                        <th scope="col">Business Description</th>
                        <th scope="col">Remove Row</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(csvData, index) in newRecord" :key="index">
                        <td>{{ csvData.business_name }}</td>
                        <td>{{ csvData.phone_number }}</td>
                        <td>{{ csvData.business_email }}</td>
                        <td>{{ csvData.website_url }}</td>
                        <td>{{ csvData.street_address }}</td>
                        <td>{{ csvData.city }}</td>
                        <td>{{ csvData.zip_code }}</td>
                        <td>{{ csvData.state }}</td>
                        <td>{{ csvData.capterra_business_id }}</td>
                        <td>{{ csvData.trustpilot_profile }}</td>
                        <td>{{ csvData.google_business_id }}</td>
                        <td>{{ csvData.image_url_link }}</td>
                        <td>{{ csvData.logo }}</td>
                        <td>{{ csvData.business_description }}</td>
                        <td>
                          <button @click="deleteRow(index)">Remove</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SelectCategory :categories="categoryOptions" @subCategories="getSubCategories($event)"/>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import { VueCsvImport } from "vue-csv-import";
import SelectCategory from "../../../../components/selectCategoryAndSubCategory.vue";
export default {
  components: {
    VueCsvImport,
    Layout,
    PageHeader,
    SelectCategory
  },
  data() {
    return {
      categoryOptions:null,
      csvFileType: [
        "text/csv",
        "text/x-csv",
        "application/vnd.ms-excel",
        "text/plain",
      ],
      parseCsv: null,
      newRecord: null,
      title: "Upload Bulk Business",
    };
  },
  watch: {
    parseCsv(newValue, oldValue) {
      // console.log(newValue);
      return (this.newRecord = newValue.slice(1));
    },
  },
  mounted(){
    this.allCategories();
  },
  methods: {
    allCategories(){
      this.$axios
        .get("categories")
        .then((response) => {
          this.categories = response.data.data;
          this.categoryOptions = response.data.data;
          //   console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    deleteRow(index) {
      this.parseCsv.splice(index, 1);
    },
    checkData() {
      // console.log(this.newRecord);
      this.$axios
        .post("upload-file-content", this.newRecord)
        .then((response) => {
          
          this.triggerSwal(response.data.message, "success");
          this.newRecord = null;
          this.parseCsv = null;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getSubCategories(data){
      let subCategory_id=[];
      data.forEach((element) => {
        subCategory_id.push(element.id);
      });
      this.newRecord.forEach((element)=>{
        element.sub_category_id=subCategory_id;
      });
      // console.log(this.newRecord);
    }
  },
};
</script>
