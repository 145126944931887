<template>
    <b-modal
      id="modal-5"
      v-model="showModel"
      title="Select Category And Sub Category"
      hide-footer
      size="lg"
      centered
    >
      <div class="card-body">
          <div class="row">
            <div class="col-lg-6">
              <div class="mb-3">
                <label class="form-label" for="category"
                          >Category</label
                        >
                <multiselect
                        v-model="categorySelected"
                        :multiple="true"
                        :options="categories"
                        :preserve-search="true"
                        label="title"
                        track-by="title"
                        @select="changeCategory($event)"
                        >
                        </multiselect>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mb-3">
                <label class="form-label" for="sub_category"
                          >Sub Category</label
                        >
                        <multiselect
                          v-model="subCategorySelected"
                          :multiple="true"
                          :options="subcategories"
                          :preserve-search="true"
                          label="title"
                          track-by="title"
                        >
                        </multiselect>
              </div>
            </div>
            
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="text-end">
                <button
                  @click="closeModel"
                  type="button"
                  class="btn btn-primary close"
                  aria-label="Close"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
      </div>
    </b-modal>
  </template>
  <script>
  import Multiselect from "vue-multiselect";
  export default {
    name: "SelectCategoryAndSubCategory",
    props:['categories'],
    components:{Multiselect},
    data() {
      return {
        subcategories:[],
        subCategorySelected:[],
        categorySelected:[],
        showModel: false,
      };
    },
    
    methods: {
      closeModel(e) {
        // console.log(this.subCategorySelected);
        this.$emit("subCategories",this.subCategorySelected);
        // console.log('test');
        // console.log(this.categories);
        // this.showModel = false;
      },
      changeCategory(val){
        this.$axios.get("subCategories/"+ val.id).then(response=>{
          response.data.data.forEach(element=>{
            this.subcategories.push(element);
          })
        })
      },
    },
  };
  </script>